<template>
  <div id="resume" class="resume p-3 p-lg-5 container-fluid" data-bs-target="#resume">
    <h1 class="title_resume fw-bold mx-auto">RESUME</h1>
    <!-- Bootstrap Timeline -->
    <section class="bsb-timeline-4 bg-light py-3 py-md-5 py-xl-8">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10 col-md-12 col-xl-10 col-xxl-9">
            <ul class="timeline">
              <li class="timeline-item right">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Bachelor FH</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Sep 2021 - Jul 2025</h6>
                        <p class="card-text m-0">EEIT, HSLU, Horw</p>
                        <p class="card-text m-0">Expected graduation: July 2025</p>
                        <p class="card-text m-0">Dual Specialization:</p>
                        <p class="card-text m-0">1) Mechatronics/Automation/Robotics</p>
                        <p class="card-text m-0">2) Energy and drive systems</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item left">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">AMZ Racing</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Sep 2024 - now</h6>
                        <p class="card-text m-0">Focus Student</p>
                        <p class="card-text m-0">Work package: LV-AMS & LV-Supply</p>
                        <p class="card-text m-0">(as my industrial work and bachelors thesis)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item right">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Working Student</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Aug 2024 - now</h6>
                        <p class="card-text m-0">Autcomp Automation AG, Stansstad, 50 %</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item left">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Semester Abroad</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Feb 2024 - Jun 2024</h6>
                        <p class="card-text m-0">Murdoch University, Perth Australia</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item right">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Voluntary Work</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Nov 2023 - Feb 2024</h6>
                        <p class="card-text m-0">Repair Café, Bourbaki Lucerne</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item left">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Electrical Planner EFZ</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Aug 2022 - Sep 2023</h6>
                        <p class="card-text m-0">HT Infraserv AG, Entlebuch, Summer holidays</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item right">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Student Help</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Nov 2020 - Jul 2021</h6>
                        <p class="card-text m-0">TopShop AG, Rothenburg, 20 %</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item left">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Start Bachelor</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Sep 2021</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item right">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Vocational Baccalaurete</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Aug 2020 - Jul 2021</h6>
                        <p class="card-text m-0">BBZB, Lucerne</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item left">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Electrical Planner EFZ</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Jul 2018 - Jul 2020</h6>
                        <p class="card-text m-0">Network 41 AG, Sursee, 100 %</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline-item right">
                <div class="timeline-body">
                  <div class="timeline-meta">
                  </div>
                  <div class="timeline-content timeline-indicator">
                    <div class="card border-0 shadow">
                      <div class="card-body p-xl-4">
                        <h2 class="card-title mb-2">Apprenticeship</h2>
                        <h6 class="card-subtitle text-secondary mb-3">Aug 2014 - Jul 2018</h6>
                        <p class="card-text m-0">Electrical Planer, Network
                          41 AG, Sursee</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: 'HomeAboutMe',
  props: {
    msg: String,
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    const elements = document.querySelectorAll('.timeline-item.right, .timeline-item.left');
    elements.forEach((element) => {
      const direction = element.classList.contains('right') ? 700 : -700;
      gsap.from(element, {
        x: direction,
        opacity: 0,
        duration: 1,
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          end: 'bottom 20%',
          toggleActions: 'play none none reverse',
        },
      });
    });
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "https://unpkg.com/bootstrap@5.3.3/dist/css/bootstrap.min.css";
@import "https://unpkg.com/bs-brain@2.0.4/tutorials/timelines/timeline-4/assets/css/timeline-4.css";

.resume {
  background-color: #52796f;
}

.bg-light {
  background-color: #52796f !important;
}

.title_resume {
  font-size: 3rem;
}

.p-10 {
  padding: 7rem !important;
  /* 112px */
}

.timeline-item.right::after {
  border-color: #eb5e28;
}

.timeline-item.left::after {
  border-color: #eb5e28;
}

.timeline-content.timeline-indicator {
  position: relative;
  /* Establish a new stacking context */
  z-index: 2;
  /* Ensure indicators are above the lines */
}

.timeline-item {
  position: relative;
  /* Make sure content is positioned correctly */
  z-index: 1;
}

/* Media query for smartphones (up to 768px wide) */
@media (max-width: 768px) {
  .title_resume {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
  }

  .technology {
    padding-top: 0rem;
    padding-bottom: 1.5rem;
  }

  .interests {
    padding-top: 0rem;
    padding-bottom: 1.5rem;
  }

  .language {
    padding-top: 0rem;
    padding-bottom: 1.5rem;
  }

  .hobbies {
    padding-top: 0rem;
    padding-bottom: 1.5rem;
  }
}
</style>
